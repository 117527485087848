import React from "react";
import "./SlideIntro.scss";
import { BackgroundIcons } from "../Icons/Icons";
import ColorBanner from "../ColorBanner/ColorBanner";
import LazyImage from "../LazyImage/LazyImage";
import Art1 from "../../images/low_res/RBG_art_Jesse_Harp.jpg";
import ImageLogo from "../../images/RBG-Logo.svg";

interface SlideIntroProps {}

const section1Icons = [
  { type: "Star", style: { width: "5vw", top: "3vh", left: "6vw" } },
  { type: "Circle", style: { width: "1.5vw", top: "19vh", left: "9vw" } },
  { type: "Circle", style: { width: "1.5vw", top: "8vh", left: "16vw" } },
  { type: "Square", style: { width: "29vw", top: "27vh", left: "-1vw" } },
  {
    type: "CircleBlack",
    style: {
      width: "1.5vw",
      top: "36vh",
      left: "17vw",
      mixBlendMode: "difference",
    },
  },
  {
    type: "CircleBlack",
    style: {
      width: "1.5vw",
      top: "42vh",
      left: "17vw",
      mixBlendMode: "difference",
    },
  },
  {
    type: "CircleFa",
    style: { width: "2.5vw", bottom: "16vh", left: "-1.5vw" },
  },
  { type: "Circle", style: { width: "1.5vw", bottom: "23vh", left: "26vw" } },
  { type: "Asterix", style: { width: "9vw", bottom: "-1vh", left: "28vw" } },
  { type: "CheckBox", style: { width: "8vw", top: "20vh", left: "35vw" } },
  { type: "Circle", style: { width: "1.5vw", top: "27vh", left: "45vw" } },
  { type: "Star", style: { width: "5.5vw", top: "42vh", left: "55vw" } },
  { type: "CircleFa", style: { width: "2vw", bottom: "26vh", left: "60vw" } },
  { type: "CrossCircle", style: { width: "10vw", top: "9vh", left: "65vw" } },
  { type: "Circle", style: { width: "1.5vw", top: "60vh", left: "75vw" } },
  { type: "CircleFaLight", style: { width: "5vw", top: "0vh", left: "75vw" } },
  { type: "Equal", style: { width: "10vw", bottom: "20vh", left: "80vw" } },
  { type: "Burst", style: { width: "10vw", top: "30vh", left: "82vw" } },
  { type: "Circle", style: { width: "1.5vw", top: "35vh", left: "96vw" } },
  { type: "Circle", style: { width: "1.5vw", top: "43vh", left: "97vw" } },
];

const section2Icons = [
  { type: "Circle", style: { width: "1.5vw", bottom: "40vh", left: "60vw" } },
  { type: "Star", style: { width: "5vw", bottom: "1vh", left: "60vw" } },
  { type: "Asterix", style: { width: "6vw", bottom: "45vh", left: "70vw" } },
  { type: "Circle", style: { width: "1.5vw", top: "10vh", left: "85vw" } },
  { type: "CircleFa", style: { width: "2.5vw", bottom: "10vh", left: "90vw" } },
];

const SlideIntro: React.FC<SlideIntroProps> = () => {
  return (
    <>
      <section data-scroll-section className="rbg-intro section-1of2">
        <BackgroundIcons icons={section1Icons} introIcons={true} />

        <section
          className="rbg-slide"
          data-scroll
          data-scroll-repeat
          style={{ paddingLeft: "env(safe-area-inset-left)" }} // for mobile left side bar
        >
          <div className="rbg-intro__sections-container">
            <div className="rbg-intro__section--left">
              <ColorBanner type="secondary" position="left" speed={1} />
              <div
                className="rbg-intro__title"
                data-scroll
                data-scroll-speed={1}
                data-scroll-position="left"
              >
                <img src={ImageLogo} alt="" />
              </div>
              <ColorBanner
                type="secondary"
                position="left"
                speed={1}
                colors={["red", "green", "blue"]}
              />
              <h2
                className="rbg-intro__subtitle"
                data-scroll
                data-scroll-speed={1}
                data-scroll-position="left"
              >
                <span className="rbg-intro__name font--white">
                  Ruth Bader Ginsburg
                </span>
                <span className="rbg-intro__dates">
                  March 15th, 1933 - September 18th, 2020
                </span>
              </h2>
            </div>
            <div className="rbg-intro__section--right">
              <ColorBanner
                type="secondary"
                speed={1}
                colors={["red", "green", "blue"]}
              />
              <p
                className="rbg-intro-slide__text text-indent padding-top--small"
                data-scroll
                data-scroll-speed={1}
                data-scroll-position="left"
              >
                It’s hard for younguns to understand how
                <span className="font--white">
                  <i> different</i>
                </span>{" "}
                life was in the United States before
                <span className="font-mabry-medium font--white">
                  {" "}
                  Ruth Bader Ginsburg{" "}
                </span>
                came along. Before
                <span className="font-mabry-medium font--white"> RBG, </span>
                women couldn’t
                <span className="font--white"> open a bank account </span>on
                their own, they couldn’t
                <span className="font--white"> serve on a jury</span>, and they
                didn’t have
                <span className="font--white">
                  {" "}
                  autonomy over their own bodies
                </span>
                . In short, they were not{" "}
                <span className="font--white"> equal citizens.</span>
                <span className="font-mabry-medium font--white"> RBG </span>
                changed{" "}
                <span className="font--white">
                  <i> all </i>
                </span>
                of that.
              </p>
              <br></br>
              <p
                className="rbg-intro-slide__text text-indent padding-top--small"
                data-scroll
                data-scroll-speed={1}
                data-scroll-position="left"
              >
                The following is a celebration of her life and work as a
                <span className="font--white"> litigator</span>, a
                <span className="font--white"> jurist</span>, and as a
                <span className="font--white"> champion for women </span>
                everywhere. The one and only...
              </p>
              <div
                className="rbg__quote-from"
                data-scroll
                data-scroll-speed={1}
                data-scroll-position="left"
              >
                <div className="quote-from__line"></div>
                <p className="rbg-intro-slide__text">
                  <span className="font--white">
                    The <i>Notorious</i>
                    <span className="font-mabry-medium"> RBG.</span>
                  </span>
                </p>
              </div>
              <ColorBanner
                type="secondary"
                colors={["red", "green", "blue"]}
                speed={1}
              />
            </div>
          </div>
        </section>
      </section>

      <section
        style={{ visibility: "hidden" }}
        data-scroll-section
        className="rbg-intro section-2of2"
      >
        <BackgroundIcons icons={section2Icons} />

        <section className="rbg-slide" data-scroll data-scroll-repeat>
          <div className="rbg-slide__sections-container--flex">
            <div className="rbg-slide__section--left">
              <div
                className="rbg-slide__image c-image"
                data-scroll
                data-scroll-repeat
                data-scroll-direction="vertical"
                data-scroll-speed={0.5}
              >
                <LazyImage src={Art1} alt="Jesse Harp" />
              </div>
            </div>
            <div className="rbg-slide__section--right">
              <div className="rbg-slide__subtitle">
                <ColorBanner
                  type="secondary"
                  colors={["red", "green", "blue"]}
                  speed={0.2}
                />
                <p
                  className="padding-top--small"
                  data-scroll
                  data-scroll-speed={0.2}
                >
                  <span className="font-mabry-medium font--white">RBG </span>
                  battled
                </p>
                <p
                  style={{ marginLeft: "2vw" }}
                  data-scroll
                  data-scroll-speed={0.4}
                >
                  <span className="font--white">
                    <i>—and overcame—</i>
                  </span>
                </p>
                <p
                  className="margin-bottom--large"
                  style={{ marginLeft: "6vw" }}
                  data-scroll
                  data-scroll-speed={0.8}
                >
                  sexism personally
                </p>
                <div
                  className="rbg-slide__artist c-text"
                  data-scroll
                  data-scroll-repeat
                >
                  <p className="rbg-slide__artist-title">Artist:</p>
                  <p>Jesse Harp</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

export default SlideIntro;
