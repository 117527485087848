import React from "react";
import "./index.scss";
import initLocomotive from "../../hooks/locomotive-scroll";
import MobileCoverPage from "../../components/MobileCoverPage/MobileCoverPage";
import RBGNarrative from "../../components/RBGNarrative/RBGNarrative";
const Footer = React.lazy(() => import("../../components/Footer/Footer"));

const Index = () => {
  const isSSR = typeof window === "undefined";

  const [resourcesActive, setResourcesActive] = React.useState(false);
  const [creditsActive, setCreditsActive] = React.useState(false);
  const [outroAnimation, setOutroAnimation] = React.useState(false);
  const [sidebarIsActive, setSidebarActive] = React.useState(false);

  let locomotiveContainer: HTMLDivElement | null = null;

  const toggleResources = React.useCallback(() => {
    setResourcesActive(!resourcesActive);
  }, [resourcesActive]);

  const toggleCredits = React.useCallback(() => {
    setCreditsActive(!creditsActive);
  }, [creditsActive]);

  React.useEffect(() => {
    if (locomotiveContainer) {
      initLocomotive(
        locomotiveContainer,
        setResourcesActive,
        setOutroAnimation,
        setSidebarActive
      );
    }
  }, [locomotiveContainer]);

  return (
    <div
      className={`o-scroll ${outroAnimation ? "c-outro" : ""}`}
      data-scroll-container
      id="js-scroll"
      data-horizontal="true"
      ref={(e) => (locomotiveContainer = e)}
    >
      <div className="content">
        <MobileCoverPage />

        <RBGNarrative
          className={resourcesActive ? "c-footer" : ""}
          toggleResources={toggleResources}
          outroAnimation={outroAnimation}
          sidebarIsActive={sidebarIsActive}
        />

        {!isSSR && (
          <React.Suspense fallback={null}>
            <Footer
              resourcesActive={resourcesActive ? "c-resources" : ""}
              creditsActive={creditsActive ? "c-credits" : ""}
              toggleResources={toggleResources}
              toggleCredits={toggleCredits}
            />
          </React.Suspense>
        )}
      </div>
    </div>
  );
};

export default Index;
