import React from "react";
import ColorBanner from "../ColorBanner/ColorBanner";
import LazyImage from "../LazyImage/LazyImage";
import { BackgroundIcons, ArrowRight } from "../Icons/Icons";
import Art1 from "../../images/low_res/RBG_art_Pete_McDonald.png";

const section1Icons = [
  {
    type: "Burst",
    style: {
      width: "27vw",
      bottom: "-10vh",
      left: "1vw",
    },
  },
  { type: "Circle", style: { width: "1.5vw", top: "14vh", left: "23vw" } },
  { type: "ArrowCircle", style: { width: "7vw", top: "26vh", left: "17vw" } },
  { type: "CircleFa", style: { width: "2.5vw", top: "40vh", left: "24vw" } },
  {
    type: "Star",
    style: {
      width: "5vw",
      top: "7vh",
      left: "32vw",
    },
  },
  {
    type: "TriangleFa",
    style: {
      width: "4vw",
      bottom: "11vh",
      left: "33vw",
      transform: "rotate(-25deg)",
    },
  },
  { type: "Asterix", style: { width: "9vw", top: "-6vh", left: "43vw" } },
  {
    type: "Square",
    style: {
      width: "25vw",
      top: "0vh",
      left: "55vw",
      transform: "rotate(27deg)",
    },
  },
  {
    type: "CircleBlack",
    style: {
      width: "1.5vw",
      top: "34vh",
      left: "72.5vw",
      mixBlendMode: "difference",
    },
  },
  { type: "Circle", style: { width: "1.5vw", top: "37vh", left: "53vw" } },
  { type: "Circle", style: { width: "1.5vw", top: "41vh", left: "54vw" } },
  { type: "Circle", style: { width: "1.5vw", bottom: "34vh", left: "52vw" } },
  { type: "Circle", style: { width: "1.5vw", bottom: "17vh", left: "61vw" } },
  {
    type: "Star",
    style: {
      width: "7vw",
      bottom: "23vh",
      left: "54vw",
      transform: "rotate(55deg)",
    },
  },
  {
    type: "CheckBox",
    style: {
      width: "6vw",
      top: "-4.5vh",
      left: "80vw",
      transform: "rotate(-15deg)",
    },
  },

  { type: "Circle", style: { width: "1.5vw", top: "24vh", left: "84.2vw" } },
  { type: "Circle", style: { width: "1.5vw", top: "47vh", left: "80vw" } },
  { type: "CircleFa", style: { width: "2.5vw", top: "59vh", left: "80vw" } },
];

const section2Icons = [
  { type: "Circle", style: { width: "1.5vw", top: "23vh", left: "50vw" } },
  {
    type: "Eye",
    style: {
      width: "8vw",
      top: "11vh",
      left: "56vw",
      transform: "rotate(-15deg)",
    },
  },
  { type: "Circle", style: { width: "1.5vw", top: "41vh", left: "63vw" } },
  { type: "CircleFa", style: { width: "2.5vw", top: "40vh", left: "24vw" } },
  { type: "Circle", style: { width: "1.5vw", top: "50vh", left: "82vw" } },
  { type: "Circle", style: { width: "1.5vw", top: "38vh", left: "85vw" } },
  {
    type: "Book",
    style: {
      width: "7vw",
      bottom: "-1vh",
      left: "74vw",
      transform: "rotate(25deg)",
    },
  },
];

const Slide4 = () => {
  return (
    <>
      <section
        style={{ visibility: "hidden" }}
        data-scroll-section
        className="rbg-slide-4 section-1of2"
      >
        <BackgroundIcons icons={section1Icons} />

        <section className="rbg-slide" data-scroll data-scroll-repeat>
          <div className="rbg-slide__sections-container--block">
            <div
              className="rbg-slide__section--top"
              data-scroll
              data-scroll-speed={1}
            >
              <div className="rbg-slide__content" data-scroll>
                <p className="rbg-slide__text text-indent">
                  <span className="font-mabry-medium font--white">RBG </span>
                  fought against gender discrimination,{" "}
                  <span className="font--white">regardless of sex.</span> Case
                  in point, these legal victories she secured for
                  <span className="font--white"> male caregivers </span>and
                  <span className="font--white"> men </span>
                  fighting for survivor benefits under{" "}
                  <span className="font--white">Social Security.</span>
                </p>
              </div>
            </div>
            <div
              className="rbg-slide__section--bottom"
              data-scroll
              data-scroll-speed={1}
            >
              <div className="rbg-slide__header">
                <ColorBanner
                  type="tertiary"
                  colors={["orange", "yellow", "green"]}
                />
                <div className="rbg-slide__header-text">
                  <div className="rbg-slide__arrow">
                    <p>
                      <ArrowRight />
                    </p>
                  </div>
                  <div className="rbg-slide__title">
                    <p>
                      <span className="font-mabry-medium">
                        <span className="font--white">Men</span> are entitled to
                        the same{" "}
                      </span>
                      <span className="font--white">
                        <i>caregiving</i>
                      </span>{" "}
                      and{" "}
                      <span className="font--white">
                        <i>Social Security</i>
                      </span>{" "}
                      rights as <span className="font--white">women.</span>
                    </p>
                    <ColorBanner
                      type="tertiary"
                      colors={["orange", "yellow", "green"]}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>

      <section
        style={{ visibility: "hidden" }}
        data-scroll-section
        className="rbg-slide-4 section-2of2"
      >
        <BackgroundIcons icons={section2Icons} />

        <section className="rbg-slide" data-scroll data-scroll-repeat>
          <div className="rbg-slide__sections-container--flex">
            <div className="rbg-slide__section--left">
              <div
                className="rbg-slide__image c-image"
                data-scroll
                data-scroll-repeat
                data-scroll-speed={0.8}
              >
                <LazyImage src={Art1} alt="Pete McDonald" />
              </div>
            </div>

            <div className="rbg-slide__section--right">
              <div className="rbg-slide__subtitle">
                <ColorBanner
                  type="secondary"
                  colors={["red", "green", "blue"]}
                  speed={0.5}
                />
                <p
                  className="padding-top--small font--white"
                  data-scroll
                  data-scroll-speed={0.5}
                >
                  <span className="font-mabry-medium">RBG</span> co-founded the
                </p>
                <p className="font--white" data-scroll data-scroll-speed={0.5}>
                  first law journal
                </p>
                <p
                  className="margin-bottom--large font--white"
                  data-scroll
                  data-scroll-speed={0.5}
                >
                  on <i>women’s rights</i>
                </p>
                <div
                  className="rbg-slide__artist c-text"
                  data-scroll
                  data-scroll-repeat
                  data-scroll-speed={0.5}
                >
                  <p className="rbg-slide__artist-title">Artist:</p>
                  <p>Pete McDonald</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

export default Slide4;
