import React from "react";
import "./ColorBanner.scss";

interface ColorBannerProps {
  type: string;
  position?: string;
  speed?: number;
  direction?: string;
  colors?: string[];
  className?: string;
}

const ColorBanner: React.FC<ColorBannerProps> = (props) => {
  return (
    <>
      {props.type === "primary" && (
        <div
          className={`banner--vertical ${props.className}`}
          data-scroll
          data-scroll-position={`${props.position}`}
        >
          <div className="banner__color banner__color--red"></div>
          <div className="banner__color banner__color--green"></div>
          <div className="banner__color banner__color--blue"></div>
        </div>
      )}
      {props.type === "secondary" && (
        <div
          className={`banner--horizontal ${props.className}`}
          data-scroll
          data-scroll-position={`${props.position}`}
          data-scroll-direction={props.direction}
          data-scroll-speed={props.speed}
        >
          {props.colors &&
            props.colors.map((color: string) => {
              return (
                <div
                  key={color}
                  className={`banner__color banner__color--${color}`}
                ></div>
              );
            })}
        </div>
      )}
      {props.type === "tertiary" && (
        <div
          className="banner--horizontal"
          data-scroll
          data-scroll-position={`${props.position}`}
          data-scroll-direction={props.direction}
          data-scroll-speed={props.speed}
        >
          {props.colors &&
            props.colors.map((color: string, index: number) => {
              const width = `${index % 2 === 0 ? "10%" : "100%"}`;

              return (
                <div
                  key={color}
                  className={`banner__color banner__color--${color}`}
                  style={{ width: width }}
                ></div>
              );
            })}
        </div>
      )}
    </>
  );
};

export default ColorBanner;

ColorBanner.defaultProps = {
  position: "",
  speed: 0,
  direction: "horizontal",
  colors: [
    "pink",
    "red",
    "orange",
    "yellow",
    "green",
    "turquoise",
    "blue",
    "purple",
  ],
};
