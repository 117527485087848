import React from "react";
import "./RBGNarrative.scss";
import ColorBanner from "../../components/ColorBanner/ColorBanner";
import SlideIntro from "../SlideIntro/SlideIntro";
import Slide1 from "../SlidesMain/Slide1";
import Slide2 from "../SlidesMain/Slide2";
import Slide3 from "../SlidesMain/Slide3";
import Slide4 from "../SlidesMain/Slide4";
import Slide5 from "../SlidesMain/Slide5";
import Slide6 from "../SlidesMain/Slide6";
import Slide7 from "../SlidesMain/Slide7";
import Slide8 from "../SlidesMain/Slide8";
import SlideOutro from "../SlideOutro/SlideOutro";
import { ArrowDown, ArrowRight, ArrowLeft } from "../Icons/Icons";

interface RBGNarrativeProps {
  className: string;
  toggleResources(): void;
  outroAnimation: boolean;
  sidebarIsActive: boolean;
}

const RBGNarrative: React.FC<RBGNarrativeProps> = (props) => {
  return (
    <div className={`rbg-narrative ${props.className}`}>
      <ColorBanner
        type="primary"
        className={`banner-main ${props.outroAnimation ? "c-outro" : ""}`}
      />
      <div
        data-scroll
        className={`banner-animated__reveal ${
          props.outroAnimation ? "c-outro" : ""
        }`}
      >
        <ColorBanner type="secondary" className="banner-animated" />
      </div>

      <SlideIntro />

      <Slide1 />

      <Slide2 />

      <Slide3 />

      <Slide4 />

      <Slide5 />

      <Slide6 />

      <Slide7 />

      <Slide8 />

      <SlideOutro outroAnimation={props.outroAnimation ? "c-outro" : ""} />

      <div
        className={`rbg__sidebar-resources ${
          props.sidebarIsActive ? "c-sidebar" : ""
        }`}
      >
        <button onClick={props.toggleResources}>
          <ArrowLeft /> More Resources
        </button>
        <p>
          RBG in RGB <ArrowDown />
        </p>
      </div>

      <button
        className={`rbg__btn-resources ${
          props.sidebarIsActive ? "c-sidebar" : ""
        }`}
        style={{ mixBlendMode: "difference" }}
        onClick={props.toggleResources}
      >
        More Resources <ArrowDown />
      </button>

      <button
        className={`rbg-arrow--right ${props.outroAnimation ? "c-outro" : ""}`}
        style={{ mixBlendMode: "difference" }}
      >
        <ArrowRight />
      </button>
    </div>
  );
};

export default RBGNarrative;
