import React from "react";
import ColorBanner from "../ColorBanner/ColorBanner";
import LazyImage from "../LazyImage/LazyImage";
import { BackgroundIcons, ArrowRight } from "../Icons/Icons";

import Art1 from "../../images/low_res/RBG_art_Leonardo_Santamaria.png";

const section1Icons = [
  {
    type: "CrossCircle",
    style: {
      width: "10vw",
      top: "0vh",
      left: "22vw",
      transform: "rotate(34deg)",
    },
  },
  {
    type: "Star",
    style: {
      width: "5vw",
      top: "39vh",
      left: "18vw",
      transform: "rotate(45deg)",
    },
  },
  { type: "Circle", style: { width: "1.5vw", top: "62vh", left: "25vw" } },
  { type: "Circle", style: { width: "1.5vw", top: "81vh", left: "20vw" } },
  {
    type: "Square",
    style: {
      width: "35vw",
      top: "17vh",
      left: "31vw",
      transform: "rotate(13deg)",
    },
  },
  {
    type: "QuoteStart",
    style: {
      width: "3vw",
      top: "62vh",
      left: "29vw",
    },
  },
  {
    type: "QuoteEnd",
    style: {
      width: "3vw",
      top: "67vh",
      left: "37vw",
      mixBlendMode: "difference",
    },
  },
  {
    type: "Heart",
    style: {
      width: "6vw",
      bottom: "19vh",
      left: "29vw",
      transform: "rotate(-20deg)",
    },
  },
  {
    type: "CircleBlack",
    style: {
      width: "1.5vw",
      top: "38vh",
      left: "42vw",
      mixBlendMode: "difference",
    },
  },
  {
    type: "CircleBlack",
    style: {
      width: "1.5vw",
      top: "26vh",
      left: "57vw",
      mixBlendMode: "difference",
    },
  },
  {
    type: "Asterix",
    style: {
      width: "5vw",
      top: "8vh",
      left: "39vw",
    },
  },
  { type: "Star", style: { width: "6vw", top: "-3vh", left: "52vw" } },
  { type: "CircleFa", style: { width: "2vw", top: "59vh", left: "70vw" } },
  { type: "Circle", style: { width: "1.5vw", top: "48vh", left: "73vw" } },
  { type: "Circle", style: { width: "1.5vw", top: "54vh", left: "80vw" } },
  {
    type: "ArrowCrossCircle",
    style: {
      width: "7vw",
      bottom: "5vh",
      left: "68vw",
    },
  },
  {
    type: "ParallelogramFa",
    style: {
      width: "16vw",
      top: "6vh",
      left: "73vw",
    },
  },
];

const section2Icons = [
  { type: "Circle", style: { width: "1.5vw", top: "23vh", left: "57vw" } },
  { type: "Circle", style: { width: "1.5vw", top: "30vh", left: "59vw" } },
  { type: "Book", style: { width: "6vw", top: "40vh", left: "60vw" } },
  { type: "Burst", style: { width: "12vw", top: "6vh", left: "73vw" } },
  { type: "Circle", style: { width: "1.5vw", top: "28vh", left: "88vw" } },
];

const Slide5 = () => {
  return (
    <>
      <section
        style={{ visibility: "hidden" }}
        data-scroll-section
        className="rbg-slide-5 section-1of2"
      >
        <BackgroundIcons icons={section1Icons} />

        <section className="rbg-slide" data-scroll data-scroll-repeat>
          <div className="rbg-slide__sections-container--block">
            <div
              className="rbg-slide__section--top"
              data-scroll
              data-scroll-speed={1}
            >
              <div className="rbg-slide__header">
                <ColorBanner
                  type="tertiary"
                  colors={["yellow", "green", "turquoise"]}
                />
                <div className="rbg-slide__header-text">
                  <div className="rbg-slide__arrow">
                    <p>
                      <ArrowRight />
                    </p>
                  </div>
                  <div className="rbg-slide__title">
                    <p>
                      <span className="font-mabry-medium">Juries must </span>
                      <br />
                      <span style={{ marginLeft: "6vw" }}>
                        <span className="font-mabry-medium"> include </span>
                        <span className="font--white">
                          <i>women.</i>
                        </span>
                      </span>
                    </p>
                    <ColorBanner
                      type="tertiary"
                      colors={["yellow", "green", "turquoise"]}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              className="rbg-slide__section--bottom"
              data-scroll
              data-scroll-speed={1}
            >
              <div className="rbg-slide__content" data-scroll>
                <p className="rbg-slide__text text-indent">
                  In 1979 it was still considered optional for women to serve on
                  juries.
                  <span className="font-mabry-medium font--white">
                    {" "}
                    RBG{" "}
                  </span>{" "}
                  won the day with her argument before the court.
                </p>
                <br />
                <p className="rbg-slide__text text-indent font--white">
                  <i>
                    Women belong in all places where decisions are being made.
                  </i>
                </p>
                <br />
                <p className="rbg-slide__text text-indent">
                  Next time you get a jury summons you have her to thank ;)
                </p>
              </div>
            </div>
          </div>
        </section>
      </section>

      <section
        style={{ visibility: "hidden" }}
        data-scroll-section
        className="rbg-slide-5 section-2of2"
      >
        <BackgroundIcons icons={section2Icons} />

        <section className="rbg-slide" data-scroll data-scroll-repeat>
          <div className="rbg-slide__sections-container--flex">
            <div className="rbg-slide__section--left">
              <div
                className="rbg-slide__image c-image"
                data-scroll
                data-scroll-repeat
                data-scroll-speed={0.5}
              >
                <LazyImage src={Art1} alt="Leonardo_Santamaria" />
              </div>
            </div>

            <div className="rbg-slide__section--right">
              <div
                className="rbg-slide__artist c-text"
                style={{ position: "relative", top: "10%" }}
                data-scroll
                data-scroll-repeat
                data-scroll-direction="vertical"
                data-scroll-speed={-0.15}
              >
                <p className="rbg-slide__artist-title">Artist:</p>
                <p>Leonardo Santamaria</p>
              </div>
              <div className="rbg-slide__subtitle">
                <ColorBanner
                  type="secondary"
                  colors={["red", "green", "blue"]}
                  direction={"vertical"}
                  speed={0}
                />
                <p
                  className="padding-top--small"
                  data-scroll
                  data-scroll-direction="vertical"
                  data-scroll-speed={0.15}
                >
                  <span className="font-mabry-medium font--white">RBG </span>
                  graduated{" "}
                  <span className="font--white">
                    <i>first</i>
                  </span>
                </p>
                <p
                  className=""
                  data-scroll
                  data-scroll-direction="vertical"
                  data-scroll-speed={0.15}
                >
                  in her class from
                </p>
                <p
                  className=""
                  data-scroll
                  data-scroll-direction="vertical"
                  data-scroll-speed={0.15}
                >
                  <span className="font--white">
                    <i>Columbia Law School</i>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

export default Slide5;
