import React from "react";
import "./SlideOutro.scss";
import ColorBanner from "../ColorBanner/ColorBanner";
import { BackgroundIcons } from "../Icons/Icons";

interface SlideOutroProps {
  outroAnimation: string;
}

const section1Icons = [
  {
    type: "Equal",
    style: {
      width: "11vw",
      bottom: "23vh",
      left: "-7vw",
      transform: "rotate(-7deg)",
    },
  },
  { type: "CircleFa", style: { width: "2.5vw", top: "54vh", left: "7vw" } },
  {
    type: "Square",
    style: {
      width: "46vw",
      top: "5vh",
      left: "7vw",
      transform: "rotate(17deg)",
    },
  },
  {
    type: "QuoteStart",
    style: {
      width: "3vw",
      top: "11vh",
      left: "3vw",
    },
  },
  {
    type: "QuoteEnd",
    style: {
      width: "3vw",
      top: "26vh",
      left: "26vw",
      mixBlendMode: "difference",
    },
  },
  {
    type: "QuoteStart",
    style: {
      width: "3vw",
      top: "45vh",
      left: "23vw",
      mixBlendMode: "difference",
    },
  },
  {
    type: "QuoteEnd",
    style: {
      width: "3vw",
      top: "65vh",
      left: "54vw",
    },
  },
  {
    type: "CircleBlack",
    style: {
      width: "1.5vw",
      bottom: "12vh",
      left: "17vw",
      mixBlendMode: "difference",
    },
  },
  {
    type: "CircleBlack",
    style: {
      width: "1.5vw",
      bottom: "18vh",
      left: "21vw",
      mixBlendMode: "difference",
    },
  },
  {
    type: "CircleBlack",
    style: {
      width: "1.5vw",
      bottom: "32vh",
      left: "36vw",
      mixBlendMode: "difference",
    },
  },
  {
    type: "CircleBlack",
    style: {
      width: "1.5vw",
      bottom: "37vh",
      left: "32vw",
      mixBlendMode: "difference",
    },
  },
  {
    type: "ArrowCircle",
    style: {
      width: "10vw",
      top: "-3vh",
      left: "60vw",
      transform: "rotate(18deg)",
    },
  },
  {
    type: "Star",
    style: {
      width: "21vw",
      top: "21vh",
      left: "51vw",
      transform: "rotate(12deg)",
    },
  },
  { type: "Circle", style: { width: "1.5vw", bottom: "23vh", left: "58vw" } },
  { type: "CircleFa", style: { width: "2vw", top: "54vh", left: "73vw" } },
  { type: "Circle", style: { width: "1.5vw", top: "66vh", left: "70vw" } },
];

const SlideOutro: React.FC<SlideOutroProps> = (props) => {
  return (
    <>
      <section data-scroll-section className="rbg-outro section-1of2">
        <BackgroundIcons icons={section1Icons} />

        <section className="rbg-slide" data-scroll data-scroll-speed={1}>
          <div className="rbg-intro__sections-container">
            <div className="rbg-outro__section">
              <div style={{ width: "55%" }}>
                <ColorBanner
                  type="secondary"
                  colors={["red", "green", "blue"]}
                />
                <p
                  className="rbg-slide__text text-indent padding-top--small"
                  data-scroll
                  style={{ fontStyle: "italic" }}
                >
                  It was my great good fortune to have the opportunity to
                  participate in the long effort to place{" "}
                  <span className="font--white">
                    equal citizenship stature for women
                  </span>{" "}
                  on the basic human-rights agenda.
                </p>
                <div
                  className="rbg__quote-from"
                  data-scroll
                  style={{ padding: "1vw 0" }}
                >
                  <div className="quote-from__line"></div>
                  <p className="rbg-slide__text font-mabry-medium font--white">
                    Ruth Bader Ginsburg
                  </p>
                </div>
              </div>
              <div style={{ marginLeft: "30%" }}>
                <p
                  className="rbg-slide__text"
                  data-scroll
                  style={{ fontStyle: "italic" }}
                >
                  “If the game shakes me or breaks me <br /> I hope it makes me
                  a better
                  <span className="font--white"> [wo]</span>man, take a better
                  stand” <br /> Put money in my mom’s hand, <br /> Get my
                  daughter this college grant so she don’t need no man. <br />{" "}
                  Stay far from timid, only make moves when your heart’s in it{" "}
                  <br /> And live the phrase{" "}
                  <span className="font--white" style={{ fontStyle: "normal" }}>
                    ‘Sky’s the limit’
                  </span>
                </p>
                <div
                  className="rbg__quote-from"
                  data-scroll
                  style={{ padding: "1vw 0" }}
                >
                  <div className="quote-from__line"></div>
                  <p className="rbg-intro-slide__text font-mabry-medium font--white">
                    {" "}
                    Biggie Smalls
                  </p>
                </div>
                <ColorBanner
                  type="secondary"
                  colors={["red", "green", "blue"]}
                />
              </div>
            </div>
          </div>
        </section>
      </section>

      <section data-scroll-section className="rbg-outro section-2of2">
        <BackgroundIcons
          outroIcons={true}
          outroAnimation={props.outroAnimation}
        />

        <section className="rbg-outro rbg-slide" style={{ width: "100vw" }}>
          <div
            className="rbg-outro__section--center"
            style={{ mixBlendMode: "difference" }}
          >
            <ColorBanner type="secondary" colors={["red", "green", "blue"]} />
            <p
              className={`rbg-outro__title ${props.outroAnimation} padding-top--small`}
            >
              <span style={{ fontStyle: "italic" }}>RIP</span>{" "}
              <span className="font-mabry-medium">RBG</span>
            </p>
            <ColorBanner type="secondary" colors={["red", "green", "blue"]} />
          </div>
        </section>
      </section>
    </>
  );
};

export default SlideOutro;
