import React from "react";
import ColorBanner from "../ColorBanner/ColorBanner";
import { BackgroundIcons, ArrowRight } from "../Icons/Icons";
import Art1 from "../../images/video/RBG_art_Jacob_Myrick.mp4";

const section1Icons = [
  { type: "Triangle", style: { width: "8vw", top: "3vh", left: "-10vw" } },
  { type: "Circle", style: { width: "1.5vw", top: "8vh", left: "21vw" } },
  { type: "Circle", style: { width: "1.5vw", bottom: "8vh", left: "20vw" } },
  { type: "BurstVote", style: { width: "10vw", top: "-10vh", left: "30vw" } },
  {
    type: "CrossCircle",
    style: {
      width: "9vw",
      top: "14vh",
      left: "33vw",
      transform: "rotate(20deg)",
    },
  },
  { type: "Circle", style: { width: "1.5vw", top: "10vh", left: "45vw" } },
  { type: "Circle", style: { width: "1.5vw", top: "16vh", left: "50vw" } },
  { type: "Equal", style: { width: "3vw", bottom: "46vh", left: "45vw" } },
  { type: "CircleFa", style: { width: "2.5vw", bottom: "26vh", left: "47vw" } },
  { type: "Star", style: { width: "5vw", bottom: "2vh", left: "39vw" } },
  { type: "Circle", style: { width: "1.5vw", bottom: "15vh", left: "50vw" } },
  { type: "Circle", style: { width: "1.5vw", bottom: "18vh", left: "55vw" } },
  { type: "Star", style: { width: "5vw", top: "10vh", left: "60vw" } },
  {
    type: "Square",
    style: {
      width: "36vw",
      top: "20vh",
      left: "50vw",
      transform: "rotate(13deg)",
    },
  },
  {
    type: "CircleBlack",
    style: {
      width: "1.5vw",
      bottom: "16vh",
      left: "63vw",
      mixBlendMode: "difference",
    },
  },
  {
    type: "CircleBlack",
    style: {
      width: "1.5vw",
      top: "31vh",
      left: "71vw",
      mixBlendMode: "difference",
    },
  },
  {
    type: "CircleBlack",
    style: {
      width: "1.5vw",
      top: "53vh",
      left: "70vw",
      mixBlendMode: "difference",
    },
  },
  {
    type: "CircleBlack",
    style: {
      width: "1.5vw",
      top: "42vh",
      left: "80vw",
      mixBlendMode: "difference",
    },
  },
  { type: "Asterix", style: { width: "9vw", top: "7vh", left: "85vw" } },
  { type: "Circle", style: { width: "1.5vw", top: "30vh", left: "93vw" } },
];

const section2Icons = [
  {
    type: "Parallelogram",
    style: { width: "8vw", bottom: "15vh", left: "70vw" },
  },
  { type: "Circle", style: { width: "1.5vw", bottom: "8vh", left: "80vw" } },
  { type: "Circle", style: { width: "1.5vw", bottom: "13vh", left: "83vw" } },
  { type: "Star", style: { width: "7vw", top: "30vh", left: "95vw" } },
];

const section3Icons = [
  {
    type: "QuoteStart",
    style: { width: "3vw", top: "20vh", left: "30vw" },
  },
  {
    type: "Equal",
    style: {
      width: "7vw",
      bottom: "10vh",
      left: "40vw",
      transform: "rotate(-20deg)",
    },
  },
  { type: "CircleFa", style: { width: "2vw", top: "5vh", left: "50vw" } },
  {
    type: "Parallelogram",
    style: {
      width: "30vw",
      top: "-3vh",
      left: "50vw",
      transform: "rotate(10deg)",
    },
  },
  {
    type: "CircleBlack",
    style: {
      width: "1.5vw",
      top: "30vh",
      left: "65vw",
      mixBlendMode: "difference",
    },
  },
  {
    type: "CircleBlack",
    style: {
      width: "1.5vw",
      top: "20vh",
      left: "70vw",
      mixBlendMode: "difference",
    },
  },
  {
    type: "CircleBlack",
    style: {
      width: "1.5vw",
      top: "10vh",
      left: "75vw",
      mixBlendMode: "difference",
    },
  },
  {
    type: "CircleBlack",
    style: {
      width: "1.5vw",
      top: "40vh",
      left: "73vw",
      mixBlendMode: "difference",
    },
  },
  {
    type: "QuoteEnd",
    style: { width: "3vw", top: "55vh", left: "55vw" },
  },
  { type: "Circle", style: { width: "1.5vw", bottom: "15vh", left: "55vw" } },
  { type: "Circle", style: { width: "1.5vw", bottom: "35vh", left: "65vw" } },
  {
    type: "Star",
    style: {
      width: "6vw",
      top: "40vh",
      left: "75vw",
      transform: "rotate(-20deg)",
    },
  },
  { type: "Circle", style: { width: "1.5vw", top: "35vh", left: "80vw" } },
  { type: "Circle", style: { width: "1.5vw", top: "30vh", left: "82vw" } },
  {
    type: "TriangleFa",
    style: {
      width: "20vw",
      bottom: "-4vh",
      left: "70vw",
      transform: "rotate(25deg)",
    },
  },
  { type: "CircleFa", style: { width: "2.5vw", bottom: "35vh", left: "80vw" } },
  { type: "CheckBox", style: { width: "5vw", top: "19vh", left: "88vw" } },
  { type: "Book", style: { width: "9vw", top: "30vh", right: "-7vw" } },
];

const Slide1 = () => {
  return (
    <>
      <section
        style={{ visibility: "hidden" }}
        data-scroll-section
        className="rbg-slide-1 section-1of3"
      >
        <BackgroundIcons icons={section1Icons} />

        <section className="rbg-slide" data-scroll data-scroll-repeat>
          <div className="rbg-slide__sections-container--block">
            <div
              className="rbg-slide__section--top"
              data-scroll
              data-scroll-speed={1}
            >
              <div className="rbg-slide__header">
                <ColorBanner
                  type="tertiary"
                  colors={["purple", "pink", "red"]}
                />
                <div className="rbg-slide__header-text">
                  <div className="rbg-slide__arrow">
                    <p>
                      <ArrowRight />
                    </p>
                    <p>
                      <ArrowRight />
                    </p>
                  </div>
                  <div className="rbg-slide__title">
                    <p>
                      <span className="font-mabry-medium">
                        Employers
                        <span className="font--white">
                          {" "}
                          cannot discriminate{" "}
                        </span>{" "}
                        <br />
                        against employees based on
                      </span>
                      <br />
                      <span className="font--white">
                        <i> gender </i>
                      </span>
                      <span className="font-mabry-medium"> or </span>
                      <span className="font--white">
                        <i>reproductive choices.</i>
                      </span>
                    </p>
                    <ColorBanner
                      type="tertiary"
                      colors={["purple", "pink", "red"]}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              className="rbg-slide__section--bottom"
              data-scroll
              data-scroll-speed={1}
            >
              <div className="rbg-slide__content">
                <p className="rbg-slide__text text-indent" data-scroll>
                  In 1978, as a litigator,
                  <span
                    className="font-mabry-medium font--white"
                    style={{ fontStyle: "normal" }}
                  >
                    {" "}
                    RBG{" "}
                  </span>
                  helped pass the{" "}
                  <span className="font--white">
                    {" "}
                    <i>Pregnancy Discrimination Act, </i>
                  </span>
                  an amendment to
                  <span className="font--white">
                    {" "}
                    <i>Title VII.</i>
                  </span>
                </p>
                <br />
                <p className="rbg-slide__text text-indent" data-scroll>
                  <span className="font--white">
                    <i>
                      The decision whether or not to bear a child is central to
                      a woman's life, to her well-being and dignity. When the
                      government controls that decision for her, she is being
                      treated as less than a full adult human responsible for
                      her own choices.
                    </i>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </section>
      </section>

      <section
        style={{ visibility: "hidden" }}
        data-scroll-section
        className="rbg-slide-1 section-2of3"
      >
        <BackgroundIcons icons={section2Icons} />

        <section className="rbg-slide" data-scroll data-scroll-repeat>
          <div className="rbg-slide__sections-container--flex">
            <div className="rbg-slide__section--left">
              <div
                className="rbg-slide__image c-image"
                data-scroll
                data-scroll-repeat
                data-scroll-speed={0.5}
              >
                <video src={Art1} autoPlay muted loop playsInline></video>
              </div>
            </div>
            <div className="rbg-slide__section--right">
              <div className="rbg-slide__subtitle">
                <ColorBanner
                  type="tertiary"
                  colors={["purple", "pink", "red"]}
                  speed={0}
                />
                <div
                  className="rbg-slide__artist padding-top--small c-text"
                  data-scroll
                  data-scroll-repeat
                  data-scroll-direction="vertical"
                  data-scroll-speed={0.15}
                >
                  <p className="rbg-slide__artist-title">Artist:</p>
                  <p>Jacob Myrick</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>

      <section
        style={{ visibility: "hidden" }}
        data-scroll-section
        className="rbg-slide-1 rbg-slide-quote section-3of3"
      >
        <BackgroundIcons icons={section3Icons} />

        <section className="rbg-slide-quote" data-scroll data-scroll-speed={1}>
          <div className="rbg-slide-quote__section--left">
            <ColorBanner type="secondary" colors={["red", "green", "blue"]} />
            <p
              className="rbg-intro-slide__text text-indent padding-top--small"
              data-scroll
            >
              <i>
                ...People should not be held back because they were born{" "}
                <span className="font--white">female</span>, born{" "}
                <span className="font--white">male</span>, born—what’s the line
                from Gilbert & Sullivan—a Russian, Turk or Prussian, or whatever{" "}
                <span className="font--white">national origin</span>, whatever
                <span className="font--white"> religion</span>. The core idea is
                that
                <span className="font--white">
                  {" "}
                  we should not be held back from pursuing our full talents
                </span>
                , from contributing what we could contribute to the society,
                <span className="font--white">
                  {" "}
                  because we fit into a certain mold
                </span>
                , because we belong to a group that historically has been the
                object of discrimination.
              </i>
            </p>
            <div className="rbg__quote-from" data-scroll>
              <div className="quote-from__line"></div>
              <p className="rbg-intro-slide__text font-mabry-medium font--white">
                Ruth Bader Ginsburg
              </p>
            </div>
            <ColorBanner type="secondary" colors={["red", "green", "blue"]} />
          </div>
        </section>
      </section>
    </>
  );
};

export default Slide1;
