import React from "react";
import ColorBanner from "../ColorBanner/ColorBanner";
import LazyImage from "../LazyImage/LazyImage";
import { BackgroundIcons, ArrowRight } from "../Icons/Icons";
import Art1 from "../../images/low_res/RBG_art_Ben_Nichols.png";

const section1Icons = [
  {
    type: "Star",
    style: {
      width: "6vw",
      bottom: "23vh",
      left: "13vw",
      transform: "rotate(-7deg)",
      mixBlendMode: "difference",
    },
  },
  { type: "CircleFa", style: { width: "3vw", top: "21vh", left: "20vw" } },
  { type: "Circle", style: { width: "1.5vw", bottom: "37vh", left: "25vw" } },
  { type: "Circle", style: { width: "1.5vw", top: "11vh", left: "28vw" } },
  {
    type: "CrossCircle",
    style: {
      width: "22vw",
      top: "22vh",
      left: "20vw",
      transform: "rotate(16deg)",
    },
  },
  {
    type: "Equal",
    style: {
      width: "11vw",
      top: "1vh",
      left: "36vw",
      transform: "rotate(-8deg)",
    },
  },
  { type: "CircleFa", style: { width: "2vw", bottom: "4vh", left: "32vw" } },
  { type: "Circle", style: { width: "1.5vw", bottom: "38vh", left: "45vw" } },
  {
    type: "Star",
    style: {
      width: "6vw",
      top: "12vh",
      left: "52vw",
      transform: "rotate(22deg)",
    },
  },
  {
    type: "Parallelogram",
    style: {
      width: "23vw",
      top: "24vh",
      left: "47vw",
      transform: "rotate(-7deg)",
    },
  },
  {
    type: "CircleBlack",
    style: {
      width: "1.5vw",
      top: "42vh",
      left: "58vw",
      mixBlendMode: "difference",
    },
  },
  { type: "Circle", style: { width: "1.5vw", top: "7vh", left: "61vw" } },
  {
    type: "BurstCircle",
    style: {
      width: "14vw",
      top: "-10vh",
      left: "65vw",
    },
  },
  { type: "CircleFa", style: { width: "2vw", top: "12vh", left: "79vw" } },
  { type: "Circle", style: { width: "1.5vw", top: "37vh", left: "81vw" } },
  { type: "Circle", style: { width: "1.5vw", top: "42vh", left: "79vw" } },
  {
    type: "CheckBox",
    style: {
      width: "5vw",
      bottom: "14vh",
      left: "79vw",
      transform: "rotate(-2deg)",
    },
  },
];

const section2Icons = [
  {
    type: "Asterix",
    style: { width: "6vw", bottom: "26vh", left: "53vw" },
    transform: "rotate(-25deg)",
  },
  { type: "Circle", style: { width: "1.5vw", top: "13vh", left: "65vw" } },
  { type: "Circle", style: { width: "1.5vw", top: "18vh", left: "76vw" } },
  { type: "Circle", style: { width: "1.5vw", top: "25vh", left: "74vw" } },
  {
    type: "Eye",
    style: {
      width: "6vw",
      top: "-1vh",
      left: "74vw",
      transform: "rotate(-20deg)",
    },
  },
];

const Slide8 = () => {
  return (
    <>
      <section
        style={{ visibility: "hidden" }}
        data-scroll-section
        className="rbg-slide-8 section-1of2"
      >
        <BackgroundIcons icons={section1Icons} />

        <section className="rbg-slide" data-scroll data-scroll-repeat>
          <div className="rbg-slide__sections-container--block">
            <div
              className="rbg-slide__section--top"
              data-scroll
              data-scroll-speed={1}
            >
              <div className="rbg-slide__header">
                <ColorBanner
                  type="tertiary"
                  colors={["blue", "purple", "pink"]}
                />
                <div className="rbg-slide__header-text">
                  <div className="rbg-slide__arrow">
                    <p>
                      <ArrowRight />
                    </p>
                  </div>
                  <div className="rbg-slide__title">
                    <p className="font-mabry-medium">
                      <span className="font--white">Women’s </span>autonomy
                      <br />
                      <span>
                        over their{" "}
                        <span className="font--white">own bodies.</span>
                      </span>
                    </p>
                    <ColorBanner
                      type="tertiary"
                      colors={["blue", "purple", "pink"]}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              className="rbg-slide__section--bottom"
              data-scroll
              data-scroll-speed={1}
            >
              <div className="rbg-slide__content">
                <p className="rbg-slide__text text-indent">
                  The fight for{" "}
                  <span className="font--white">women´s autonomy</span> in cases
                  like 2016’s{" "}
                  <span className="font--white">
                    <i>Whole Women’s Health v. Hellerstedt</i>
                  </span>{" "}
                  was one of the great struggles of{" "}
                  <span className="font-mabry-medium font--white">RBG's</span>{" "}
                  life. And now that she’s gone her work is in danger of being
                  overturned by the Trump-appointed justice angling to replace
                  her.
                </p>
                <br />
                <p
                  className="rbg-slide__text text-indent"
                  style={{ marginLeft: "2vw" }}
                >
                  Get <span className="font--white">involved.</span> Get{" "}
                  <span className="font--white">active.</span> And{" "}
                  <span className="font--white">
                    <i>vote.</i>
                  </span>
                </p>
                <br />
                <p
                  className="rbg-slide__text text-indent font--white"
                  style={{ marginLeft: "6vw" }}
                >
                  The struggle continues...
                </p>
              </div>
            </div>
          </div>
        </section>
      </section>

      <section
        style={{ visibility: "hidden" }}
        data-scroll-section
        className="rbg-slide-8 section-2of2"
      >
        <BackgroundIcons icons={section2Icons} />

        <section className="rbg-slide" data-scroll data-scroll-repeat>
          <div className="rbg-slide__sections-container--flex">
            <div className="rbg-slide__section--left">
              <div
                className="rbg-slide__image c-image"
                data-scroll
                data-scroll-repeat
                data-scroll-direction="vertical"
                data-scroll-speed={-0.15}
              >
                <LazyImage src={Art1} alt="Ben_Nichols" />
              </div>
            </div>
            <div className="rbg-slide__section--right">
              <div className="rbg-slide__subtitle">
                <ColorBanner
                  type="secondary"
                  colors={["red", "green", "blue"]}
                />
                <p className="padding-top--small" data-scroll>
                  <span className="font-mabry-medium font--white">RBG </span>
                  gained notice
                </p>
                <p className="margin-bottom--large" data-scroll>
                  for her{" "}
                  <span className="font--white">
                    <i>impassioned dissents</i>
                  </span>
                </p>
                <div
                  className="rbg-slide__artist c-text"
                  data-scroll
                  data-scroll-repeat
                  data-scroll-direction="vertical"
                  data-scroll-speed={0.15}
                >
                  <p className="rbg-slide__artist-title">Artist:</p>
                  <p>Ben Nichols</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

export default Slide8;
