import React from "react";
import "./Icons.scss";
import FacebookImage from "../../images/Asset facebook.png";
import TwitterImage from "../../images/Asset Twitter.png";
import { ReactComponent as Parallelogram } from "../../images/svg/Parallelogram.svg";
import { ReactComponent as ArrowCircle } from "../../images/svg/ArrowCircle.svg";
import { ReactComponent as ArrowCrossCircle } from "../../images/svg/ArrowCrossCircle.svg";
import { ReactComponent as Asterix } from "../../images/svg/Asterix.svg";
import { ReactComponent as Book } from "../../images/svg/Book.svg";
import { ReactComponent as Burst } from "../../images/svg/Burst.svg";
import { ReactComponent as BurstCircle } from "../../images/svg/BurstCircle.svg";
import { ReactComponent as BurstVote } from "../../images/svg/BurstVote.svg";
import { ReactComponent as Check } from "../../images/svg/Check.svg";
import { ReactComponent as CheckBox } from "../../images/svg/CheckBox.svg";
import { ReactComponent as Circle } from "../../images/svg/Circle.svg";
import { ReactComponent as CircleFa } from "../../images/svg/CircleFa.svg";
import { ReactComponent as CircleFaLight } from "../../images/svg/CircleFaLight.svg";
import { ReactComponent as CircleBlack } from "../../images/svg/CircleBlack.svg";
import { ReactComponent as CrossCircle } from "../../images/svg/CrossCircle.svg";
import { ReactComponent as Equal } from "../../images/svg/Equal.svg";
import { ReactComponent as EqualBlack } from "../../images/svg/EqualBlack.svg";
import { ReactComponent as Eye } from "../../images/svg/Eye.svg";
import { ReactComponent as Heart } from "../../images/svg/Heart.svg";
import { ReactComponent as ParallelogramFa } from "../../images/svg/ParallelogramFa.svg";
import { ReactComponent as QuoteStart } from "../../images/svg/QuoteStart.svg";
import { ReactComponent as QuoteEnd } from "../../images/svg/QuoteEnd.svg";
import { ReactComponent as Smile } from "../../images/svg/Smile.svg";
import { ReactComponent as Square } from "../../images/svg/Square.svg";
import { ReactComponent as Star } from "../../images/svg/Star.svg";
import { ReactComponent as TriangleFa } from "../../images/svg/TriangleFa.svg";
import { ReactComponent as Triangle } from "../../images/svg/Triangle.svg";
import { ReactComponent as Vote } from "../../images/svg/Vote.svg";

import ColorBanner from "../ColorBanner/ColorBanner";

export const AtomOrCookie = () => <span className="icon-container">X</span>;
export const ArrowDown = () => <span className="icon-container">↓</span>;
export const ArrowRight = () => <span className="icon-container">→</span>;
export const ArrowLeft = () => <span className="icon-container">←</span>;
export const ArrowUp = () => <span className="icon-container">↑</span>;
export const Facebook = () => (
  <img className="icon-social" src={FacebookImage} alt="instagram icon" />
);
export const Twitter = () => (
  <img className="icon-social" src={TwitterImage} alt="twitter icon" />
);

interface IBackgroundIcon {
  type: string;
  style: any;
}

interface BackgroundIconsProps {
  icons?: IBackgroundIcon[];
  introIcons?: boolean;
  outroIcons?: boolean;
  outroAnimation?: string;
}

export const BackgroundIcons: React.FC<BackgroundIconsProps> = (props) => {
  const iconMapping: {
    [key: string]: JSX.Element;
  } = {
    ArrowCircle: <ArrowCircle />,
    ArrowCrossCircle: <ArrowCrossCircle />,
    Asterix: <Asterix />,
    Book: <Book />,
    Burst: <Burst />,
    BurstCircle: <BurstCircle />,
    BurstVote: <BurstVote />,
    Check: <Check />,
    CheckBox: <CheckBox />,
    Circle: <Circle />,
    CircleBlack: <CircleBlack />,
    CircleFa: <CircleFa />,
    CircleFaLight: <CircleFaLight />,
    CrossCircle: <CrossCircle />,
    Equal: <Equal />,
    EqualBlack: <EqualBlack />,
    Eye: <Eye />,
    Heart: <Heart />,
    Parallelogram: <Parallelogram />,
    ParallelogramFa: <ParallelogramFa />,
    QuoteEnd: <QuoteEnd />,
    QuoteStart: <QuoteStart />,
    Smile: <Smile />,
    Square: <Square />,
    Star: <Star />,
    Triangle: <Triangle />,
    TriangleFa: <TriangleFa />,
    Vote: <Vote />,
  };

  return (
    <div
      style={{ visibility: props.introIcons ? "visible" : "hidden" }}
      className={`background-icons__container`}
    >
      {props.outroIcons && (
        <div className={`background-icon outro`}>
          <ColorBanner type="secondary" className={props.outroAnimation} />
        </div>
      )}

      {props.icons &&
        props.icons.map((icon: IBackgroundIcon, index) => {
          return (
            <div
              key={`${icon.type + index}`}
              className={`background-icon ${icon.type}`}
              style={icon.style}
            >
              {icon.type === "Outro" ? (
                <ColorBanner type="secondary" />
              ) : (
                iconMapping[icon.type]
              )}
            </div>
          );
        })}
    </div>
  );
};
