import React from "react";
import ColorBanner from "../ColorBanner/ColorBanner";
import LazyImage from "../LazyImage/LazyImage";
import { BackgroundIcons, ArrowRight } from "../Icons/Icons";
import Art1 from "../../images/low_res/RBG_art_Nicole_Pappas.png";
import Art2 from "../../images/low_res/RBG_art_German_DiCiccio.png";

const section1Icons = [
  {
    type: "Square",
    style: {
      width: "36vw",
      top: "2vh",
      left: "2vw",
      transform: "rotate(26deg)",
    },
  },
  {
    type: "Equal",
    style: {
      width: "16vw",
      top: "11vh",
      left: "-2vw",
      transform: "rotate(-7deg)",
      mixBlendMode: "difference",
    },
  },
  { type: "Circle", style: { width: "1.5vw", bottom: "26vh", left: "3vw" } },
  { type: "CircleFa", style: { width: "2vw", bottom: "28vh", left: "9vw" } },
  {
    type: "ArrowCircle",
    style: {
      width: "7vw",
      bottom: "1vh",
      left: "5vw",
      transform: "rotate(23deg)",
    },
  },
  { type: "Circle", style: { width: "1.5vw", bottom: "8vh", left: "14vw" } },
  { type: "Circle", style: { width: "1.5vw", top: "5vh", left: "11vw" } },
  {
    type: "CircleBlack",
    style: {
      width: "1.5vw",
      top: "37vh",
      left: "17vw",
      mixBlendMode: "difference",
    },
  },
  {
    type: "CircleBlack",
    style: {
      width: "1.5vw",
      top: "52vh",
      left: "19vw",
      mixBlendMode: "difference",
    },
  },
  {
    type: "CircleBlack",
    style: {
      width: "1.5vw",
      top: "60vh",
      left: "23vw",
      mixBlendMode: "difference",
    },
  },
  { type: "CircleFa", style: { width: "2vw", top: "-1vh", left: "32vw" } },
  { type: "Circle", style: { width: "1.5vw", top: "42vh", left: "35vw" } },
  { type: "Circle", style: { width: "1.5vw", bottom: "39vh", left: "41vw" } },
  { type: "Circle", style: { width: "1.5vw", top: "22vh", left: "42vw" } },
  {
    type: "CrossCircle",
    style: {
      width: "6vw",
      bottom: "14vh",
      left: "33vw",
    },
  },
  {
    type: "Star",
    style: {
      width: "5vw",
      bottom: "26vh",
      left: "42vw",
      transform: "rotate(-2deg)",
    },
  },
  { type: "Circle", style: { width: "1.5vw", top: "4vh", left: "61vw" } },
  {
    type: "Heart",
    style: {
      width: "12vw",
      top: "22vh",
      left: "52vw",
      transform: "rotate(-22deg)",
    },
  },
  {
    type: "Burst",
    style: {
      width: "21vw",
      bottom: "-5vh",
      left: "51vw",
    },
  },
  {
    type: "ArrowCrossCircle",
    style: {
      width: "10vw",
      top: "-3vh",
      left: "68vw",
      transform: "rotate(-11deg)",
    },
  },
  { type: "CircleFa", style: { width: "2vw", top: "37vh", left: "70vw" } },
  { type: "Circle", style: { width: "1.5vw", top: "35vh", left: "74vw" } },
];

const section2Icons = [
  {
    type: "Heart",
    style: { width: "7vw", bottom: "10vh", left: "26vw" },
    transform: "rotate(-25deg)",
  },
  { type: "CircleFa", style: { width: "2.5vw", top: "40vh", left: "43vw" } },
  { type: "Circle", style: { width: "1.5vw", top: "42vh", left: "46.5vw" } },
  { type: "Circle", style: { width: "1.5vw", top: "14vh", left: "59vw" } },
  {
    type: "Equal",
    style: {
      width: "8vw",
      top: "25vh",
      left: "59vw",
    },
  },
  { type: "Circle", style: { width: "1.5vw", bottom: "14vh", left: "80vw" } },
  { type: "CircleFa", style: { width: "2vw", top: "45vh", left: "88vw" } },
];

const section3Icons = [
  {
    type: "Triangle",
    style: { width: "11vw", bottom: "13vh", left: "13vw" },
    transform: "rotate(160deg)",
  },
  { type: "Circle", style: { width: "1.5vw", top: "18vh", left: "25vw" } },
  {
    type: "QuoteStart",
    style: {
      width: "3vw",
      top: "41vh",
      left: "18vw",
    },
  },
  {
    type: "QuoteEnd",
    style: {
      width: "3vw",
      top: "46vh",
      left: "69vw",
    },
  },
  { type: "Circle", style: { width: "1.5vw", top: "59vh", left: "23vw" } },
  {
    type: "CircleBlack",
    style: {
      width: "1.5vw",
      bottom: "22vh",
      left: "20vw",
      mixBlendMode: "difference",
    },
  },
  {
    type: "Equal",
    style: {
      width: "41vw",
      top: "-14vh",
      left: "37vw",
      transform: "rotate(-7deg)",
    },
  },
  {
    type: "CrossCircle",
    style: {
      width: "11vw",
      bottom: "12vh",
      left: "56vw",
    },
  },
  {
    type: "Star",
    style: {
      width: "6vw",
      bottom: "10vh",
      left: "69vw",
    },
  },
  { type: "Circle", style: { width: "1.5vw", bottom: "26vh", left: "79vw" } },
  { type: "Circle", style: { width: "1.5vw", top: "51vh", left: "85vw" } },
  { type: "Circle", style: { width: "1.5vw", bottom: "28vh", left: "88vw" } },
];

const section4Icons = [
  { type: "Circle", style: { width: "1.5vw", top: "63vh", left: "35vw" } },
  {
    type: "CheckBox",
    style: { width: "7vw", bottom: "0vh", left: "39vw" },
    transform: "rotate(-15deg)",
  },
  {
    type: "CrossCircle",
    style: {
      width: "5vw",
      bottom: "13vh",
      left: "70vw",
      transform: "rotate(25deg)",
    },
  },
  {
    type: "CrossCircle",
    style: {
      width: "5vw",
      bottom: "14vh",
      left: "74vw",
      transform: "rotate(-15deg)",
    },
  },
  { type: "Circle", style: { width: "1.5vw", bottom: "35vh", left: "76vw" } },
  { type: "Circle", style: { width: "1.5vw", bottom: "55vh", left: "84vw" } },
  { type: "Circle", style: { width: "1.5vw", top: "3vh", left: "87vw" } },
];

const Slide7 = () => {
  return (
    <>
      <section
        style={{ visibility: "hidden" }}
        data-scroll-section
        className="rbg-slide-7 section-1of4"
      >
        <BackgroundIcons icons={section1Icons} />

        <section className="rbg-slide">
          <div className="rbg-slide__sections-container--block">
            <div
              className="rbg-slide__section--top"
              data-scroll
              data-scroll-speed={1}
            >
              <div className="rbg-slide__content" data-scroll>
                <p className="rbg-slide__text text-indent">
                  <span className="font-mabry-mediume font--white">RBG</span>{" "}
                  was a tireless advocate for marriage equality and{" "}
                  <span className="font--white">LGBTQ</span> rights and was
                  instrumental in granting{" "}
                  <span className="font--white">same-sex</span> couples the
                  right to get <span className="font--white">married</span> in
                  all <span className="font--white">50</span> states in the
                  landmark 2015 case{" "}
                  <span className="font--white">
                    <i>Obergefell v. Hodges.</i>
                  </span>
                </p>
              </div>
            </div>
            <div
              className="rbg-slide__section--bottom"
              data-scroll
              data-scroll-speed={1}
            >
              <div className="rbg-slide__header">
                <ColorBanner
                  type="tertiary"
                  colors={["turquoise", "blue", "purple"]}
                />
                <div className="rbg-slide__header-text">
                  <div className="rbg-slide__arrow">
                    <p style={{ color: "black" }}>
                      <ArrowRight />
                    </p>
                  </div>
                  <div className="rbg-slide__title">
                    <p className="font-mabry-medium">
                      Marriage <span className="font--white">equality.</span>
                    </p>
                    <ColorBanner
                      type="tertiary"
                      colors={["turquoise", "blue", "purple"]}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>

      <section
        style={{ visibility: "hidden" }}
        data-scroll-section
        className="rbg-slide-7 section-2of4"
      >
        <BackgroundIcons icons={section2Icons} />

        <section className="rbg-slide" data-scroll data-scroll-repeat>
          <div className="rbg-slide__sections-container--flex">
            <div className="rbg-slide__section--left">
              <div
                className="rbg-slide__image c-image"
                data-scroll
                data-scroll-repeat
                data-scroll-speed={0.5}
              >
                <LazyImage src={Art1} alt="Nicole_Pappas" />
              </div>
            </div>
            <div className="rbg-slide__section--right">
              <div className="rbg-slide__subtitle">
                <ColorBanner
                  type="tertiary"
                  colors={["turquoise", "blue", "purple"]}
                  speed={0}
                />
                <div
                  className="rbg-slide__artist padding-top--small c-text"
                  data-scroll
                  data-scroll-repeat
                  data-scroll-direction="vertical"
                  data-scroll-speed={0.15}
                >
                  <p className="rbg-slide__artist-title">Artist:</p>
                  <p>Nicole Pappas</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>

      <section
        style={{ visibility: "hidden" }}
        data-scroll-section
        className="rbg-slide-7 rbg-slide-quote section-3of4"
      >
        <BackgroundIcons icons={section3Icons} />

        <section className="rbg-slide-quote" data-scroll data-scroll-speed={1}>
          <div className="rbg-slide-quote__section--left">
            <ColorBanner type="secondary" colors={["red", "green", "blue"]} />
            <p className="rbg-intro-slide__text text-indent padding-top--small">
              <i>
                I didn’t change the Constitution;{" "}
                <span className="font--white">
                  the equality principle was there from the start.
                </span>{" "}
                I just was an advocate for seeing its full realization.
              </i>
            </p>
            <div className="rbg__quote-from" data-scroll>
              <div className="quote-from__line"></div>
              <p className="rbg-intro-slide__text font-mabry-medium font--white">
                Ruth Bader Ginsburg
              </p>
            </div>
            <ColorBanner type="secondary" colors={["red", "green", "blue"]} />
          </div>
        </section>
      </section>

      <section
        style={{ visibility: "hidden" }}
        data-scroll-section
        className="rbg-slide-7 section-4of4"
      >
        <BackgroundIcons icons={section4Icons} />

        <section className="rbg-slide" data-scroll data-scroll-repeat>
          <div className="rbg-slide__sections-container--flex">
            <div className="rbg-slide__section--left">
              <div
                className="rbg-slide__image--w-banner"
                data-scroll
                data-scroll-repeat
                data-scroll-speed={0.5}
              >
                <ColorBanner
                  type="secondary"
                  colors={["red", "green", "blue"]}
                />
                <div
                  className="rbg-slide__image c-image"
                  data-scroll
                  data-scroll-repeat
                >
                  <LazyImage src={Art2} alt="German_DiCiccio" />
                </div>
              </div>
            </div>
            <div className="rbg-slide__section--right">
              <div
                className="rbg-slide__artist c-text"
                style={{ position: "relative", top: "10%" }}
                data-scroll
                data-scroll-repeat
                data-scroll-direction="vertical"
                data-scroll-speed={-0.15}
              >
                <p className="rbg-slide__artist-title">Artist:</p>
                <p>Germán Di Ciccio</p>
              </div>
              <div className="rbg-slide__subtitle">
                <ColorBanner
                  type="secondary"
                  colors={["red", "green", "blue"]}
                  direction={"vertical"}
                  speed={0}
                />
                <p
                  className="padding-top--small"
                  data-scroll
                  data-scroll-direction="vertical"
                  data-scroll-speed={0.15}
                >
                  <span className="font-mabry-medium font--white">RBG </span>was
                  the first
                </p>
                <p
                  className=""
                  data-scroll
                  data-scroll-direction="vertical"
                  data-scroll-speed={0.15}
                >
                  justice to officiate a
                </p>
                <p
                  className=""
                  data-scroll
                  data-scroll-direction="vertical"
                  data-scroll-speed={0.15}
                >
                  same-sex marriage
                </p>
              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

export default Slide7;
