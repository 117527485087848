import React from "react";
import ColorBanner from "../ColorBanner/ColorBanner";
import LazyImage from "../LazyImage/LazyImage";
import { BackgroundIcons, ArrowRight } from "../Icons/Icons";
import Art1 from "../../images/low_res/RBG_art_Amelia_Giller.png";

const section1Icons = [
  { type: "Circle", style: { width: "1.5vw", top: "9vh", left: "11vw" } },
  { type: "Circle", style: { width: "1.5vw", top: "20vh", left: "14vw" } },
  { type: "CircleFa", style: { width: "2.5vw", top: "50vh", left: "16vw" } },
  { type: "Circle", style: { width: "1.5vw", bottom: "22vh", left: "14vw" } },
  {
    type: "Star",
    style: {
      width: "5vw",
      bottom: "8vh",
      left: "14vw",
      transform: "rotate(20deg)",
    },
  },
  {
    type: "Square",
    style: {
      width: "33vw",
      top: "0vh",
      left: "24vw",
      transform: "rotate(15deg)",
    },
  },
  {
    type: "CircleBlack",
    style: {
      width: "1.5vw",
      top: "45vh",
      left: "34vw",
      mixBlendMode: "difference",
    },
  },
  { type: "CircleFa", style: { width: "2.5vw", bottom: "17vh", left: "30vw" } },
  { type: "Circle", style: { width: "1.5vw", bottom: "8vh", left: "38vw" } },
  { type: "Circle", style: { width: "1.5vw", bottom: "12vh", left: "41vw" } },
  { type: "Circle", style: { width: "1.5vw", top: "67vh", left: "48vw" } },
  {
    type: "CrossCircle",
    style: {
      width: "5vw",
      top: "-3vh",
      left: "54vw",
    },
  },
  { type: "CircleFa", style: { width: "2vw", top: "5vh", left: "61vw" } },
  { type: "Circle", style: { width: "1.5vw", top: "42vh", left: "60vw" } },
  { type: "Circle", style: { width: "1.5vw", top: "26vh", left: "68vw" } },
  { type: "Equal", style: { width: "11vw", top: "48vh", left: "66vw" } },
  { type: "Eye", style: { width: "9vw", top: "7vh", left: "70vw" } },
  { type: "Asterix", style: { width: "6vw", top: "33vh", left: "75vw" } },
  { type: "Circle", style: { width: "1.5vw", bottom: "10vh", right: "21vw" } },
];

const section2Icons = [
  { type: "Circle", style: { width: "1.5vw", top: "27vh", left: "52vw" } },
  { type: "CircleFa", style: { width: "2vw", top: "72vh", left: "52vw" } },
  { type: "Equal", style: { width: "8vw", top: "19vh", left: "57vw" } },
  { type: "Circle", style: { width: "1.5vw", top: "5vh", left: "69vw" } },
  { type: "Circle", style: { width: "1.5vw", top: "13vh", left: "71vw" } },
];

const Slide2 = () => {
  return (
    <>
      <section
        style={{ visibility: "hidden" }}
        data-scroll-section
        className="rbg-slide-2 section-1of2"
      >
        <BackgroundIcons icons={section1Icons} />

        <section className="rbg-slide" data-scroll data-scroll-repeat>
          <div className="rbg-slide__sections-container--block">
            <div
              className="rbg-slide__section--top"
              data-scroll
              data-scroll-speed={1}
            >
              <div className="rbg-slide__content">
                <p className="rbg-slide__text text-indent">
                  Hard to believe now, but women were barred from colleges for
                  much of{" "}
                  <span className="font-mabry-medium font--white">RBG</span>’s
                  life. In 1996, she changed that by writing the majority
                  opinion in{" "}
                  <span className="font--white">
                    <i>United States v Virginia</i>
                  </span>{" "}
                  arguing that the school’s{" "}
                  <span className="font--white">
                    <i>'men-only'</i>
                  </span>{" "}
                  policy violated the{" "}
                  <span className="font--white">
                    <i>14th Amendment’s</i>
                  </span>{" "}
                  equal protection clause.
                </p>
              </div>
            </div>
            <div
              className="rbg-slide__section--bottom"
              data-scroll
              data-scroll-speed={1}
            >
              <div className="rbg-slide__header">
                <ColorBanner
                  type="tertiary"
                  colors={["pink", "red", "orange"]}
                />
                <div className="rbg-slide__header-text">
                  <div className="rbg-slide__arrow">
                    <p>
                      <ArrowRight />
                    </p>
                  </div>
                  <div className="rbg-slide__title">
                    <p>
                      <span className="font-mabry-medium">
                        State-funded{" "}
                        <span className="font--white">schools</span>
                        <br /> must admit{" "}
                      </span>
                      <span className="font--white">
                        <i>women.</i>
                      </span>
                    </p>
                    <ColorBanner
                      type="tertiary"
                      colors={["pink", "red", "orange"]}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>

      <section
        style={{ visibility: "hidden" }}
        data-scroll-section
        className="rbg-slide-2 section-2of2"
      >
        <BackgroundIcons icons={section2Icons} />

        <section className="rbg-slide" data-scroll data-scroll-repeat>
          <div className="rbg-slide__sections-container--flex">
            <div className="rbg-slide__section--left">
              <div
                className="rbg-slide__image--w-banner"
                data-scroll
                data-scroll-repeat
                data-scroll-speed={0.5}
              >
                <div
                  className="rbg-slide__image c-image"
                  data-scroll
                  data-scroll-repeat
                >
                  <LazyImage src={Art1} alt="Amelia_Giller" />
                </div>
                <ColorBanner
                  type="tertiary"
                  colors={["pink", "red", "orange"]}
                />
              </div>
            </div>

            <div className="rbg-slide__section--right">
              <div className="rbg-slide__subtitle">
                <ColorBanner
                  type="tertiary"
                  colors={["pink", "red", "orange"]}
                  speed={0.2}
                />
                <p
                  className="padding-top--small font-mabry-medium font--white"
                  data-scroll
                  data-scroll-speed={0.2}
                >
                  Stomping down
                </p>
                <p
                  className="font--white"
                  data-scroll
                  data-scroll-speed={0.4}
                  style={{ marginLeft: "2vw" }}
                >
                  <i>(Bye patriarchy</i>
                </p>
                <p
                  className="font--white margin-bottom--large"
                  data-scroll
                  data-scroll-speed={0.8}
                  style={{ marginLeft: "6vw" }}
                >
                  <i>see you never)</i>
                </p>
                <div
                  className="rbg-slide__artist c-text"
                  data-scroll
                  data-scroll-repeat
                >
                  <p className="rbg-slide__artist-title">Artist:</p>
                  <p>Amelia Giller</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

export default Slide2;
