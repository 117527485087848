import React from "react";
import "./MobileCoverPage.scss";
import ColorBanner from "../ColorBanner/ColorBanner";
import { ReactComponent as ArrowCurveDown } from "../../images/svg/ArrowCurveDown.svg";

const MobileCoverPage = () => {
  return (
    <div className="mobile__cover-page--portrait">
      <ColorBanner type="primary" className="mobile__cover-page__banner" />
      <div className="mobile__cover-page__content">
        <p>
          Please open in a browser <br /> and rotate your device 90° <br />{" "}
          (landscape mode) <br /> to experience
        </p>
        <p style={{ position: "relative", color: "#191918" }}>
          <span className="font-mabry-medium">RBG</span> in{" "}
          <span className="font-mabry-medium">RGB.</span>
          <span
            style={{
              width: "10vw",
              position: "absolute",
              right: "0",
              bottom: "-4vw",
            }}
          >
            <ArrowCurveDown />
          </span>
        </p>
      </div>
    </div>
  );
};

export default MobileCoverPage;
