import React from "react";
import ColorBanner from "../ColorBanner/ColorBanner";
import { BackgroundIcons, ArrowRight } from "../Icons/Icons";
import LazyImage from "../LazyImage/LazyImage";
import Art1 from "../../images/low_res/RBG_art_Shannon_Rollins.png";
import Art2 from "../../images/low_res/RBG_art_Lauren_Gaballo.png";
import Art3 from "../../images/low_res/RBG_art_Kuu_Chen.png";

const section1Icons = [
  { type: "Check", style: { width: "7vw", bottom: "10vh", left: "0vw" } },
  { type: "Asterix", style: { width: "9vw", top: "38vh", left: "15vw" } },
  { type: "Circle", style: { width: "1.5vw", top: "3vh", left: "25vw" } },
  { type: "Circle", style: { width: "1.5vw", top: "61vh", left: "23vw" } },
  { type: "Circle", style: { width: "1.5vw", bottom: "47vh", left: "29vw" } },
  { type: "CircleFa", style: { width: "2.5vw", bottom: "26vh", left: "29vw" } },
  {
    type: "Heart",
    style: {
      width: "8vw",
      bottom: "3vh",
      left: "21vw",
    },
  },
  { type: "Circle", style: { width: "1.5vw", top: "26vh", left: "39vw" } },
  { type: "CircleFa", style: { width: "2.5vw", top: "33vh", left: "45vw" } },
  {
    type: "Parallelogram",
    style: {
      width: "28vw",
      top: "34vh",
      left: "44vw",
    },
  },
  {
    type: "CircleBlack",
    style: {
      width: "1.5vw",
      top: "63vh",
      left: "52vw",
      mixBlendMode: "difference",
    },
  },
  {
    type: "CircleBlack",
    style: {
      width: "1.5vw",
      top: "67vh",
      left: "56vw",
      mixBlendMode: "difference",
    },
  },
  {
    type: "CircleBlack",
    style: {
      width: "1.5vw",
      top: "40vh",
      left: "64vw",
      mixBlendMode: "difference",
    },
  },
  {
    type: "Star",
    style: {
      width: "5vw",
      top: "26vh",
      left: "52vw",
    },
  },
  {
    type: "Equal",
    style: {
      width: "11vw",
      top: "3vh",
      left: "58vw",
      transform: "rotate(-29deg)",
    },
  },
  { type: "Circle", style: { width: "1.5vw", top: "19vh", left: "65vw" } },
  { type: "Circle", style: { width: "1.5vw", bottom: "30vh", left: "71vw" } },
  { type: "Circle", style: { width: "1.5vw", bottom: "36vh", left: "72vw" } },
  { type: "CircleFa", style: { width: "2.5vw", bottom: "3vh", left: "68vw" } },
  {
    type: "Burst",
    style: {
      width: "11vw",
      bottom: "10vh",
      left: "73vw",
    },
  },
];

const section2Icons = [
  { type: "Circle", style: { width: "1.5vw", top: "37vh", left: "57vw" } },
  { type: "Circle", style: { width: "1.5vw", top: "70vh", left: "61vw" } },
  { type: "Circle", style: { width: "1.5vw", top: "64vh", left: "63vw" } },
  { type: "CircleFa", style: { width: "3vw", top: "46vh", left: "66vw" } },
  { type: "Smile", style: { width: "5vw", top: "34vh", left: "69vw" } },
];

const section3Icons = [
  { type: "CircleFa", style: { width: "2.5vw", top: "13vh", left: "6vw" } },
  { type: "Circle", style: { width: "1.5vw", top: "40vh", left: "8vw" } },
  { type: "CheckBox", style: { width: "7vw", bottom: "23vh", left: "10vw" } },
  { type: "QuoteStart", style: { width: "3vw", top: "26vh", left: "19vw" } },
  { type: "QuoteEnd", style: { width: "3vw", top: "40vh", left: "42vw" } },
  {
    type: "Square",
    style: {
      width: "22vw",
      top: "34vh",
      left: "20vw",
      transform: "rotate(45deg)",
    },
  },
  {
    type: "QuoteStart",
    style: {
      width: "3vw",
      top: "53vh",
      left: "22vw",
      mixBlendMode: "difference",
    },
  },
  { type: "QuoteEnd", style: { width: "3vw", top: "58vh", left: "48vw" } },
  {
    type: "Star",
    style: {
      width: "6vw",
      top: "-3vh",
      left: "32vw",
      transform: "rotate(-12deg)",
    },
  },
  { type: "Burst", style: { width: "34vw", top: "0vh", left: "55vw" } },
  { type: "Circle", style: { width: "1.5vw", top: "74vh", left: "80vw" } },
  { type: "Circle", style: { width: "1.5vw", bottom: "48vh", left: "88vw" } },
  { type: "Circle", style: { width: "1.5vw", top: "52vh", left: "90vw" } },
  { type: "Heart", style: { width: "8vw", top: "68vh", left: "88vw" } },
];

const section4Icons = [
  { type: "CircleFa", style: { width: "2.5vw", top: "33vh", left: "-1vw" } },
  { type: "Smile", style: { width: "5vw", top: "13vh", left: "-2vw" } },
  { type: "Circle", style: { width: "1.5vw", top: "53vh", left: "53vw" } },
  {
    type: "Equal",
    style: {
      width: "5vw",
      top: "62vh",
      left: "53vw",
      transform: "rotate(-30deg)",
    },
  },
  { type: "Circle", style: { width: "1.5vw", top: "50vh", left: "73vw" } },
  { type: "CircleFa", style: { width: "2.5vw", top: "80vh", left: "77vw" } },
];

const section5Icons = [
  { type: "Circle", style: { width: "1.5vw", top: "50vh", left: "54vw" } },
  { type: "CircleFa", style: { width: "2.5vw", top: "51vh", left: "61vw" } },
  { type: "Circle", style: { width: "1.5vw", top: "45vh", left: "64vw" } },
  { type: "Circle", style: { width: "1.5vw", top: "74vh", left: "78vw" } },
];

const Slide3 = () => {
  return (
    <>
      <section
        style={{ visibility: "hidden" }}
        data-scroll-section
        className="rbg-slide-3 section-1of5"
      >
        <BackgroundIcons icons={section1Icons} />

        <section className="rbg-slide" data-scroll data-scroll-repeat>
          <div className="rbg-slide__sections-container--block">
            <div
              className="rbg-slide__section--top"
              data-scroll
              data-scroll-speed={1}
            >
              <div className="rbg-slide__header">
                <ColorBanner
                  type="tertiary"
                  colors={["red", "orange", "yellow"]}
                />
                <div className="rbg-slide__header-text">
                  <div className="rbg-slide__arrow">
                    <p>
                      <ArrowRight />
                    </p>
                    <p>
                      <ArrowRight />
                    </p>
                  </div>
                  <div className="rbg-slide__title">
                    <p>
                      <span className="font-mabry-medium">
                        <span className="font--white">Women</span> have the
                        right <br /> to{" "}
                      </span>
                      <span className="font--white">
                        <i>financial independence </i> <br />
                      </span>
                      <span className="font-mabry-medium">and</span>{" "}
                      <span className="font--white">
                        <i>equal benefits.</i>
                      </span>
                    </p>
                    <ColorBanner
                      type="tertiary"
                      colors={["red", "orange", "yellow"]}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              className="rbg-slide__section--bottom"
              data-scroll
              data-scroll-speed={1}
            >
              <div className="rbg-slide__content" data-scroll>
                <p className="rbg-slide__text text-indent font--white">
                  <span className="font-mabry-medium">Ginsburg’s </span>work
                  paved the way for the <i>Equal Credit Opportunity Act, </i>
                  which passed in 1974 and allowed women to apply for bank
                  accounts, credit cards, and mortgages without a male
                  co-signer.
                </p>
                <br />
                <p className="rbg-slide__text text-indent font--white">
                  And yes, this all sounds <i>insane</i> now. But you have{" "}
                  <span className="font-mabry-medium">RBG </span>
                  to thank for that.
                </p>
              </div>
            </div>
          </div>
        </section>
      </section>

      <section
        style={{ visibility: "hidden" }}
        data-scroll-section
        className="rbg-slide-3 section-2of5"
      >
        <BackgroundIcons icons={section2Icons} />

        <section className="rbg-slide" data-scroll data-scroll-repeat>
          <div className="rbg-slide__sections-container--flex">
            <div className="rbg-slide__section--left">
              <div
                className="rbg-slide__image c-image"
                data-scroll
                data-scroll-repeat
              >
                <LazyImage src={Art1} alt="Shannon_Rollins" />
              </div>
            </div>
            <div className="rbg-slide__section--right">
              <div
                className="rbg-slide__artist c-text"
                style={{ position: "relative", top: "10%" }}
                data-scroll
                data-scroll-repeat
                data-scroll-direction="vertical"
                data-scroll-speed={-0.15}
              >
                <p className="rbg-slide__artist-title">Artist:</p>
                <p>Shannon Rollins</p>
              </div>
            </div>
          </div>
        </section>
      </section>

      <section
        style={{ visibility: "hidden" }}
        data-scroll-section
        className="rbg-slide-3 rbg-slide-quote section-3of5"
      >
        <BackgroundIcons icons={section3Icons} />

        <section className="rbg-slide-quote" data-scroll data-scroll-speed={1}>
          <div className="rbg-slide-quote__section--left">
            <ColorBanner type="secondary" colors={["red", "green", "blue"]} />
            <p
              className="rbg-intro-slide__text text-indent padding-top--small"
              data-scroll
            >
              <i>
                [W]hen I’m sometimes asked when will there be enough [women on
                the supreme court]? And I say
              </i>{" "}
              <span className="font--white">‘When there are nine.’</span>{" "}
              <i>
                People are shocked.{" "}
                <span className="font--white">
                  But there’d been nine men, and nobody’s ever raised a question
                  about that.
                </span>
              </i>
            </p>
            <p
              className="rbg-intro-slide__text"
              style={{
                borderTop: "0.23vw solid #191918",
                borderBottom: "0.23vw solid #191918",
                padding: "2vw 0",
                marginTop: "2vw",
              }}
              data-scroll
            >
              <i
                style={{
                  position: "relative",
                  left: "6vw",
                  whiteSpace: "nowrap",
                }}
              >
                I ask no favor for my sex... All I ask of our brethren is that
                they <br />
                <span className="font--white">
                  take their feet off our necks.
                </span>
              </i>
            </p>
            <div className="rbg__quote-from" data-scroll>
              <div className="quote-from__line"></div>
              <p className="rbg-intro-slide__text font-mabry-medium font--white">
                Ruth Bader Ginsburg
              </p>
            </div>
            <ColorBanner type="secondary" colors={["red", "green", "blue"]} />
          </div>
        </section>
      </section>

      <section
        style={{ visibility: "hidden" }}
        data-scroll-section
        className="rbg-slide-3 section-4of5"
      >
        <BackgroundIcons icons={section4Icons} />

        <section className="rbg-slide" data-scroll data-scroll-repeat>
          <div className="rbg-slide__sections-container--flex">
            <div className="rbg-slide__section--left">
              <div
                className="rbg-slide__image--w-banner"
                data-scroll
                data-scroll-repeat
                data-scroll-speed={0.5}
              >
                <div
                  className="rbg-slide__image c-image"
                  data-scroll
                  data-scroll-repeat
                >
                  <LazyImage src={Art2} alt="Lauren_Gaballo" />
                </div>
                <ColorBanner
                  type="tertiary"
                  colors={["red", "orange", "yellow"]}
                />
              </div>
            </div>
            <div className="rbg-slide__section--right">
              <div className="rbg-slide__subtitle">
                <ColorBanner
                  type="tertiary"
                  colors={["red", "orange", "yellow"]}
                />
                <div
                  className="rbg-slide__artist padding-top--small c-text"
                  data-scroll
                  data-scroll-repeat
                  data-scroll-direction="vertical"
                  data-scroll-speed={0.15}
                >
                  <p className="rbg-slide__artist-title">Artist:</p>
                  <p>Lauren Gaballo</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>

      <section
        style={{ visibility: "hidden" }}
        data-scroll-section
        className="rbg-slide-3 section-5of5"
      >
        <BackgroundIcons icons={section5Icons} />

        <section className="rbg-slide" data-scroll data-scroll-repeat>
          <div className="rbg-slide__sections-container--flex">
            <div className="rbg-slide__section--left">
              <div
                className="rbg-slide__image c-image"
                data-scroll
                data-scroll-repeat
                data-scroll-speed={0.5}
              >
                <LazyImage src={Art3} alt="Kuu_Chen" />
              </div>
            </div>
            <div className="rbg-slide__section--right">
              <div className="rbg-slide__subtitle" style={{ top: "60%" }}>
                <ColorBanner
                  type="tertiary"
                  colors={["red", "orange", "yellow"]}
                  speed={0}
                />
                <div
                  className="rbg-slide__artist padding-top--small c-text"
                  data-scroll
                  data-scroll-repeat
                  data-scroll-direction="vertical"
                  data-scroll-speed={0.15}
                >
                  <p className="rbg-slide__artist-title">Artist:</p>
                  <p>Kuu Chen</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

export default Slide3;
