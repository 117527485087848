import React from "react";

interface LazyImage {
  src: string;
  alt: string;
  className?: string;
  style?: any;
}

const LazyImage: React.FC<LazyImage> = (props) => {
  return (
    <>
      <img
        data-scroll
        data-scroll-call="toggleLazy"
        data-scroll-lazy={props.src}
        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAABVQAAAUWAQMAAABHWqoAAAAABlBMVEVoaGZlZWN04gZgAAABVElEQVR42uzBgQAAAACAoP2pF6kCAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAGD24EAAAAAAAMj/tRFUVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVYU9OBAAAAAAAPJ/bQRVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVWmHjmkAhoEgCAaim4ceHmbgSMfBxSkzCFYLAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAHdMjfU0mRq+hq8lfA1fS/gavpbo+npq7OedFqvq69TwNXwt4Wv4WsLX+PnXU0NraC2hNbSW0BpaS2i9Y3+ZraE3iwV/QgAAAABJRU5ErkJggg=="
        alt={`RBG Artwork by ${props.alt}`}
        className={props.className}
        style={props.style}
      />
    </>
  );
};

export default LazyImage;
