import React from "react";
import ColorBanner from "../ColorBanner/ColorBanner";
import LazyImage from "../LazyImage/LazyImage";
import { BackgroundIcons, ArrowRight } from "../Icons/Icons";
import Art1 from "../../images/low_res/RBG_art_Angelica_Fernandez.png";

const section1Icons = [
  { type: "CircleFa", style: { width: "2vw", top: "3vh", left: "6vw" } },
  {
    type: "CrossCircle",
    style: {
      width: "4vw",
      top: "12vh",
      left: "27vw",
      transform: "rotate(25deg)",
    },
  },
  { type: "Circle", style: { width: "1.5vw", top: "64vh", left: "18vw" } },
  {
    type: "Star",
    style: {
      width: "5vw",
      bottom: "12vh",
      left: "5vw",
      transform: "rotate(45deg)",
    },
  },
  {
    type: "Square",
    style: {
      width: "27vw",
      top: "-2vh",
      left: "22vw",
      transform: "rotate(17deg)",
    },
  },
  {
    type: "CircleBlack",
    style: {
      width: "1.5vw",
      top: "17vh",
      left: "27vw",
      mixBlendMode: "difference",
    },
  },
  {
    type: "CircleBlack",
    style: {
      width: "1.5vw",
      top: "38vh",
      left: "34vw",
      mixBlendMode: "difference",
    },
  },
  {
    type: "CircleBlack",
    style: {
      width: "1.5vw",
      top: "30vh",
      left: "39vw",
      mixBlendMode: "difference",
    },
  },
  {
    type: "TriangleFa",
    style: {
      width: "8vw",
      bottom: "2vh",
      left: "23vw",
    },
  },
  { type: "Circle", style: { width: "1.5vw", bottom: "11vh", left: "32vw" } },
  { type: "CircleFa", style: { width: "2vw", bottom: "16vh", left: "36vw" } },
  { type: "CircleFa", style: { width: "2vw", top: "19vh", left: "48vw" } },
  {
    type: "CheckBox",
    style: {
      width: "6vw",
      top: "-5vh",
      left: "59vw",
    },
  },
  { type: "Circle", style: { width: "1.5vw", top: "26vh", left: "57vw" } },
  { type: "Circle", style: { width: "1.5vw", top: "22vh", left: "68vw" } },
  {
    type: "Star",
    style: {
      width: "5vw",
      top: "33vh",
      left: "54vw",
      transform: "rotate(18deg)",
    },
  },
  {
    type: "ArrowCircle",
    style: {
      width: "29vw",
      bottom: "-4vh",
      left: "45vw",
    },
  },
  { type: "Circle", style: { width: "1.5vw", top: "51vh", left: "75vw" } },
  { type: "Circle", style: { width: "1.5vw", top: "61vh", left: "81vw" } },
];

const section2Icons = [
  { type: "Circle", style: { width: "1.5vw", top: "14vh", left: "50vw" } },
  { type: "Asterix", style: { width: "3vw", bottom: "10vh", left: "42vw" } },
  {
    type: "Book",
    style: {
      width: "8vw",
      top: "-3vh",
      left: "64vw",
      transform: "rotate(30deg)",
    },
  },
];

const Slide6 = () => {
  return (
    <>
      <section
        style={{ visibility: "hidden" }}
        data-scroll-section
        className="rbg-slide-6 section-1of2"
      >
        <BackgroundIcons icons={section1Icons} />

        <section className="rbg-slide">
          <div className="rbg-slide__sections-container--block">
            <div
              className="rbg-slide__section--top"
              data-scroll
              data-scroll-speed={1}
            >
              <div className="rbg-slide__content">
                <p className="rbg-slide__text text-indent">
                  Another example of her fight for{" "}
                  <span className="font--white">gender equality</span> from
                  1973. Consistency is the hallmark of sound legal philosophy
                  and{" "}
                  <span className="font-mabry-medium font--white">RBG </span>
                  lived hers every day of her life.
                </p>
              </div>
            </div>
            <div
              className="rbg-slide__section--bottom"
              data-scroll
              data-scroll-speed={1}
            >
              <div className="rbg-slide__header">
                <ColorBanner
                  type="tertiary"
                  colors={["green", "turquoise", "blue"]}
                />
                <div className="rbg-slide__header-text">
                  <div className="rbg-slide__arrow">
                    <p>
                      <ArrowRight />
                    </p>
                    <p>
                      <ArrowRight />
                    </p>
                  </div>
                  <div className="rbg-slide__title">
                    <p>
                      <span className="font-mabry-medium">
                        Military women’s{" "}
                        <span className="font--white">husbands</span> can get
                        the same{" "}
                      </span>{" "}
                      <span className="font--white">
                        <i>family housing </i>
                      </span>
                      <span className="font-mabry-medium">and </span>{" "}
                      <span className="font--white">
                        <i>benefits </i>
                      </span>{" "}
                      <span className="font-mabry-medium">
                        as military <span className="font--white">wives.</span>
                      </span>
                    </p>
                    <ColorBanner
                      type="tertiary"
                      colors={["green", "turquoise", "blue"]}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>

      <section
        style={{ visibility: "hidden" }}
        data-scroll-section
        className="rbg-slide-6 section-2of2"
      >
        <BackgroundIcons icons={section2Icons} />

        <section className="rbg-slide" data-scroll data-scroll-repeat>
          <div className="rbg-slide__sections-container--flex">
            <div className="rbg-slide__section--left">
              <div
                className="rbg-slide__image--w-banner"
                data-scroll
                data-scroll-repeat
                data-scroll-speed={0.5}
              >
                <div
                  className="rbg-slide__image c-image"
                  data-scroll
                  data-scroll-repeat
                >
                  <LazyImage src={Art1} alt="Nicole_Pappas" />
                </div>
                <ColorBanner
                  type="secondary"
                  colors={["red", "green", "blue"]}
                />
              </div>
            </div>
            <div className="rbg-slide__section--right">
              <div className="rbg-slide__subtitle">
                <ColorBanner
                  type="secondary"
                  colors={["red", "green", "blue"]}
                />
                <p className="padding-top--small" data-scroll>
                  <span className="font-mabry-medium font--white">RBG</span>{" "}
                  argued six cases
                </p>
                <p className="" data-scrol>
                  before the{" "}
                  <span className="font--white">
                    <i>Supreme Court</i>
                  </span>
                </p>
                <p
                  className="margin-bottom--large"
                  style={{ marginLeft: "6vw" }}
                  data-scroll
                >
                  —and won{" "}
                  <span className="font--white">
                    <i>five</i>
                  </span>
                </p>
                <div
                  className="rbg-slide__artist c-text"
                  data-scroll
                  data-scroll-repeat
                  data-scroll-direction="vertical"
                  data-scroll-speed={0.15}
                >
                  <p className="rbg-slide__artist-title">Artist:</p>
                  <p>Angélica Fernandez</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

export default Slide6;
